<template>
<div>
<v-list-item>
    <v-list-item-content style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action style="font-weight: 300;">
      <span v-if="field.Type === 'Route Path'">
          <v-btn plain text :to="Record[field.Name]">{{Record[field.Name]}}</v-btn>
      </span>
      <span v-else-if="field.Type === 'Lookup' && Record[field.Name] && UserCanGetLookup && !field.IsMultiple">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <router-link :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id">{{Record[field.Name][field.LookupFieldName]}}</router-link>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
      <span v-else-if="field.Type === 'Lookup' && Record[field.Name] && UserCanGetLookup && field.IsMultiple">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <v-chip small v-for="lu in Record[field.Name]" :key="lu.itemObjKey">{{lu[field.LookupFieldName]}}
          </v-chip>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
      <span v-else-if="field.Type === 'Social Lookup' && Record[field.Name]">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <router-link :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id">{{Record[field.Name][field.LookupFieldName]}}</router-link>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
      <span v-else-if="field.Type === 'Lookup' && Record[field.Name] && !UserCanGetLookup">
          {{Record[field.Name][field.LookupFieldName]}}
      </span>
        <!-- {{FieldValue}} -->
    </v-list-item-action>
    <!-- types could be
    'Shared Doc Link','Route Path','Lookup','Social Lookup' and of couse Parent Field if PArentFieldObj.Type any of these..
    and then url link, commonfieldtype
     -->
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      
        LookupRecord(){
          //console.log(this.field)
          let luarray = []
          if(this.field.Array){
            luarray = this.field.Array
          }
            if(this.Record[this.field.Name]){
                //console.log(this.Record[this.field.Name].id,this.field,this.Record[this.field.Name])
                let matchingrecord = luarray.find(obj => obj.id === this.Record[this.field.Name].id)
                if(matchingrecord){
                    return matchingrecord
                }
                else{
                    return this.Record[this.field.Name]
                }
            }
        },
        LookupRoute(){
            let routes = this.$router.getRoutes()
            let routeitem = routes.find(obj => obj.meta && obj.meta.RouteEventID === 1029 && obj.meta.EntityID === this.field.RelatedBuildID)
            return routeitem
        },
        LookupRouteName(){
            return this.LookupRoute ? this.LookupRoute.path.split('/').join('') : ''
        },
        LookupRouteSecurity(){
            return this.LookupRoute ? this.LookupRoute.meta.Security : ''
        },
        LookupRouteSecurityType(){
            return this.LookupRouteSecurity ? this.LookupRouteSecurity.SecurityType : ''
        },
        UserCanGetLookup(){
            let systemrecord = this.LookupRecord
            //console.log('LookupRecord',systemrecord,this.field,this.$store.state,this.LookupRouteName)
            if(systemrecord && this.LookupRouteSecurityType === 'Business Unit'){
                //console.log('LookupRouteName',this.LookupRouteName+'viewAll',this.LookupRouteName+'viewUnitdown',this.LookupRouteName+'viewUserUnit',this.LookupRouteName+'viewOwner')
                if(this.$store.state[this.LookupRouteName+'viewAll']){
                    return true
                }
                else if(this.$store.state[this.LookupRouteName+'viewUnitdown']){
                    //LookupRecord
                    return this.userBUChildren.includes(systemrecord.Business_Unitid)
                }
                else if(this.$store.state[this.LookupRouteName+'viewUserUnit']){
                    //LookupRecord
                    return systemrecord.Business_Unitid === this.userBUID
                }
                else if(this.$store.state[this.LookupRouteName+'viewOwner']){
                    //LookupRecord
                    return systemrecord.Ownerid === this.userLoggedIn.id
                }
            }
            else if(systemrecord && this.LookupRouteSecurityType === 'Custom Roles'){
                return this.$store.state[this.LookupRouteName+'CanView']
            }
        },
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{

    }
}
</script>

<style>

</style>



